@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  @include flex-row;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  background-color: var(--color-background-primary);
  border-radius: var(--radius-round);
  padding-left: var(--space-200);
  padding-right: var(--space-200);
  // Use box-shadow instead of border to avoid the text jumping when the border width changes on focus
  box-shadow: inset 0 0 0 var(--borders-weight-sm) var(--color-border-interactive);

  &.subtle {
    background-color: var(--color-neutral-100-a);
    box-shadow: none;

    &.disabled {
      box-shadow: none;
    }

    &:focus-within {
      background-color: var(--color-background-primary);
    }
  }

  &.md {
    height: 48px;
  }

  &.sm {
    height: 36px;
  }

  &.disabled {
    background-color: var(--color-background-disabled);
    box-shadow: inset 0 0 0 var(--borders-weight-sm) var(--color-border-disabled);

    .inputIconButton {
      display: none;
    }
  }

  &:not(:focus-within):not(.disabled) {
    @include hover {
      box-shadow: inset 0 0 0 var(--borders-weight-sm) var(--color-border-interactive-hover);
    }

    // The extra check to make sure the button isn't active fixes a safari bug where the immediate mousedown on the button kicks focus
    // out of the component long enough to remove the button from the dom, cancelling the onclick from every firing.
    .inputIconButton:not(:active) {
      display: none;
    }
  }

  &:focus-within {
    box-shadow: inset 0 0 0 var(--borders-weight-md) var(--color-border-focus-default);
  }

  svg {
    flex-shrink: 0;
  }
}

.input {
  border: none !important;
  outline: none !important;
  background-color: transparent;
  flex: 1;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  @include placeholderColor(var(--color-text-placeholder));

  &.md {
    @include text-200;
    margin-left: var(--space-150);
  }

  &.sm {
    @include text-100;
    margin-left: var(--space-50);
  }

  &:disabled {
    color: var(--color-text-disabled);
    @include placeholderColor(var(--color-text-disabled));
  }
}

.inputIconButton.inputIconButton {
  color: var(--color-text-secondary);
  margin-right: calc(-12px - 2 * var(--borders-weight-md));
}
